<template>
    <form class="w-full" @submit.prevent="search()">
        <label for="default-search"
               class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">Zoeken</label>
        <div class="relative">
            <input type="search"  ref="searchBox" id="default-search" class="block  py-2 pl-3 w-full text-sm text-gray-900 bg-gray-50  border
                             border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
                             dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                   :placeholder="placeholder" required v-model="website.search.q">
            <button type="submit" class="text-white absolute right-2 bottom-1 bg-blue-900  focus:ring-5 focus:outline-none
                            hover:bg-indigo-700
                            focus:ring-blue-300 font-medium rounded-md text-sm px-4 py-1 dark:bg-blue-600 dark:hover:bg-blue-700
                            dark:focus:bg-indigo-700" title="Zoeken door producten">
                <div
                    class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg aria-hidden="true"
                         class="w-5 h-5 text-white dark:text-gray-400"
                         fill="none"
                         stroke="currentColor" viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="2"
                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                </div>
                <div class=" pl-5 min-w-0 min-h-2 " >
                    Zoeken
                </div>

            </button>
        </div>
    </form>
</template>

<script>

export default {
    components: {

    },
    props: {
        website: Object,
        page:Object,
    },
    data() {
        return {
            placeholder:'placeholder'
        };
    },

    methods: {
        search() {
            if(this.page.type==='search'){
                this.$emit('headerSearch',this.website.search.q);
            }else {
                this.$inertia.visit(route('search.page') + '/'+this.website.product+'?q=' + this.website.search.q, {method: 'get'});
            }
        },

        setPlaceholder() {
            if(this.$refs.searchBox!==null) {
                let search_width = this.$refs.searchBox.clientWidth;
                if (this.website.search.title !== undefined) {
                    this.placeholder = 'Zoek door alle ' + this.website.search.title;
                }else {
                    this.placeholder = 'Zoek door alle ' + this.website.product;
                    let limit = search_width / 10 - 6;
                    let lengtOriginal = this.placeholder.length;
                    if (limit < lengtOriginal) {
                        this.placeholder = this.placeholder.substring(0, limit);
                        this.placeholder = this.placeholder + '...';
                    }
                }
            }
        }
    },
    mounted() {
        if ( window &&  window && typeof window !== 'undefined') {
            window.addEventListener('resize', this.setPlaceholder);
        }
        this.setPlaceholder();
    }
}


</script>
